import Divider from '@components/Divider'
import PageTitle from '@components/PageTitle'
import { Layout, Main, Stack } from '@layout'
import ContactForm from '@widgets/ContactForm'
import Seo from '@widgets/Seo'
import React from 'react'

export default props => (
  <Layout {...props}>
    <Seo title='Contact' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle header="Let's Contact" subheader='' />
        <Divider />
        <ContactForm />
      </Main>
    </Stack>
  </Layout>
)
