import { ErrorMessage, Formik } from 'formik'
import React from 'react'
import { Box, Button, Input, Label, Message, Textarea } from 'theme-ui'
import * as Yup from 'yup'

const styles = {
  fontSize: {
    fontSize: `18px`
  }
}

const ContactForm = () => (
  <>
    <Formik
      initialValues={{ firstName: '', email: '', message: '' }}
      initialStatus={{ errors: [], success: false }}
      onSubmit={(values, actions) => {
        setTimeout(() => {
          console.log(values)
          const url = `${process.env.GATSBY_API_URL}`
          console.info(url)
          const data = {
            type: 'mrkdwn',
            text: `Tech blogからのお問合せ \n 名前: ${values.name} \n メールアドレス${values.email} \n お問い合せ内容: ${values.message} \n `
          }
          const xml = new XMLHttpRequest()
          xml.open('POST', url, false)
          xml.setRequestHeader(
            'content-type',
            'application/x-www-form-urlencoded;charset=UTF-8'
          )
          xml.send(`payload=${JSON.stringify(data)}`)
          actions.setSubmitting(false)
        }, 700)
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email('正しいメールアドレスを入力してください')
          .required('メールアドレスは必須です。')
      })}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValid,
        setStatus,
        status,
        touched,
        values,
        formik
      }) => {
        return (
          <form className='text-left' onSubmit={handleSubmit}>
            <Box variant='forms.row'>
              <Box variant='forms.column'>
                <Label htmlFor='contact-form-name'>Name</Label>
                <Input
                  type='text'
                  name='name'
                  sx={styles.fontSize}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                />
              </Box>
            </Box>
            <Box variant='forms.row'>
              <Box variant='forms.column'>
                <Label htmlFor='contact-form-email'>Email</Label>
                <Input
                  type='email'
                  placeholder='email@example.com'
                  id='contact-form-email'
                  name='email'
                  sx={styles.fontSize}
                  onChange={e => {
                    setStatus({ errors: [], success: false })
                    handleChange(e)
                  }}
                  onBlur={handleBlur}
                  value={values.email}
                  isInvalid={!!(touched.email && errors.email)}
                />
                <ErrorMessage name='email'>
                  {msg => <Message variant='error'>{errors.email}</Message>}
                </ErrorMessage>
              </Box>
            </Box>
            <Box variant='forms.row'>
              <Label htmlFor='contact-form-message'>Your Message</Label>
              <Textarea
                name='message'
                id='contact-form-message'
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
              />
            </Box>
            <Button type='submit'>Submit</Button>
          </form>
        )
      }}
    </Formik>
  </>
)

export default ContactForm

// ContactForm.propTypes = {
//   handleOnSubmit: PropTypes.func,
//   submitting: PropTypes.bool,
//   success: PropTypes.bool
// }
